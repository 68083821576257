import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import RequestIcon from "@mui/icons-material/ConfirmationNumber";
import InfoIcon from "@mui/icons-material/Info";
import ChatIcon from "@mui/icons-material/Chat";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import ServicesIcon from "@mui/icons-material/RoomService";
import PartnerIcon from "@mui/icons-material/BusinessCenter";
import SchedulerIcon from "@mui/icons-material/Schedule";
import GuestsIcon from '@mui/icons-material/PeopleAlt';

import { HOTEL_DATA, TIER_LIGHT, USER_DATA_KEY } from "../../utils/constants";
import Permissions from "../../enums/permissions";

import MenuItemNav from "./MenuItemNav";
import { useNotification } from "../../contexts/NotificationProvider";

const permissions_tiers = [
  Permissions.MENU_PERMISSION,
  Permissions.INFORMATIONS_PERMISSION,
  Permissions.SCHEDULER,
  Permissions.ACTIVITY_CONTROLLER,
  Permissions.SERVICES_CONTROLLER,
  Permissions.PARTNERS_CONTROLLER,
  Permissions.GUESTS_CONTROLLER,
];

export default function Sidebar() {
  var permissions = [Permissions.DASHBOARD];

  const userData = localStorage.getItem(USER_DATA_KEY);
  const { countChat, countOrder, countRequest } = useNotification();
  const hotel = localStorage.getItem(HOTEL_DATA);

  if (userData) {
    const userDataParsed = JSON.parse(userData);

    userDataParsed.permissions.forEach((element) => {
      permissions.push(element);
    });
  }

  if (hotel) {
    const hotelParsed = JSON.parse(hotel);

    if (hotelParsed.tier === TIER_LIGHT) {
      permissions = permissions_tiers;
    }
  }

  return (
    <React.Fragment>
      {permissions.includes(Permissions.DASHBOARD) && (
        <MenuItemNav
          to="/dashboard"
          icon={<DashboardIcon />}
          title="Dashboard"
          count={0}
        />
      )}

      {permissions.includes(Permissions.INFORMATIONS_PERMISSION) && (
        <MenuItemNav to="/hotel" icon={<InfoIcon />} title="Hotel" count={0} />
      )}

      {permissions.includes(Permissions.MENU_PERMISSION) && (
        <MenuItemNav
          to="/menu"
          icon={<FastfoodIcon />}
          title="Cardápio"
          count={countOrder}
        />
      )}

      {permissions.includes(Permissions.REQUEST_PERMISSION) && (
        <MenuItemNav
          to="/requests"
          icon={<RequestIcon />}
          title="Chamados"
          count={countRequest}
        />
      )}

      {permissions.includes(Permissions.CHAT_PERMISSION) && (
        <MenuItemNav
          to="/chat"
          icon={<ChatIcon />}
          title="Chat"
          count={countChat}
        />
      )}

      {permissions.includes(Permissions.SCHEDULER) && (
        <MenuItemNav
          to="/scheduler"
          icon={<SchedulerIcon />}
          title="Agendamentos"
          count={0}
        />
      )}

      {permissions.includes(Permissions.ACTIVITY_CONTROLLER) && (
        <MenuItemNav
          to="/activities"
          icon={<SportsVolleyballIcon />}
          title="Atividades"
          count={0}
        />
      )}

      {permissions.includes(Permissions.SERVICES_CONTROLLER) && (
        <MenuItemNav
          to="/services"
          icon={<ServicesIcon />}
          title="Serviços"
          count={0}
        />
      )}

      {permissions.includes(Permissions.PARTNERS_CONTROLLER) && (
        <MenuItemNav
          to="/partners"
          icon={<PartnerIcon />}
          title="Parceiros"
          count={0}
        />
      )}

      {permissions.includes(Permissions.GUESTS_CONTROLLER) && (
        <MenuItemNav
          to="/guests"
          icon={<GuestsIcon />}
          title="Hóspedes"
          count={0}
        />
      )}
    </React.Fragment>
  );
}
