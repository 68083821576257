import React, { useState, useEffect } from "react";
import { HOTEL_UUID } from "../../utils/constants";
import MenuScreen from "./Orders.screen";
import { useNotification } from "../../contexts/NotificationProvider";
import { getDatabase, ref, onValue } from "firebase/database";

export default function OrdersContainer() {
  const [loading, setLoading] = useState(true);
  const [createdOrders, setCreatedOrders] = useState([]);
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const [doneTodayOrders, setDoneTodayOrders] = useState([]);
  const [doneOrders, setDoneOrders] = useState([]);

  const { resetOrder } = useNotification();

  useEffect(() => {
    setLoading(true);

    const starCountRef = ref(
      getDatabase(),
      `orders/${localStorage.getItem(HOTEL_UUID)}`
    );

    onValue(
      starCountRef,
      async (snapshot) => {
        await onChangeItem(snapshot.val());
      },
      { onlyOnce: false }
    );

    resetOrder();
  }, []);

  function onChangeItem(data) {
    if (data) {
      const todayDay = new Date().getDate();
      const todayMonth = new Date().getMonth() + 1;
      const todayYear = new Date().getFullYear();

      setCreatedOrders([]);
      setConfirmedOrders([]);
      setDoneTodayOrders([]);
      setDoneOrders([]);

      let createdOrders = [];
      let confirmedOrders = [];
      let doneTodayOrders = [];
      let doneOrders = [];

      for (const [key, value] of Object.entries(data)) {
        for (const [key2, value] of Object.entries(data[key])) {
          if (value.status == "created") {
            createdOrders.push(value);
          }
          if (value.status == "confirmed") {
            confirmedOrders.push(value);
          }

          const orderDay = new Date(value.createdAt).getDate();
          const orderMonth = new Date(value.createdAt).getMonth() + 1;
          const orderYear = new Date(value.createdAt).getFullYear();

          if (value.status == "done") {
            if (
              orderDay == todayDay &&
              orderMonth == todayMonth &&
              orderYear == todayYear
            ) {
              doneTodayOrders.push(value);
            }
            doneOrders.push(value);
          }
        }
      }

      createdOrders.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      confirmedOrders.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      doneTodayOrders.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      doneOrders.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setCreatedOrders([...createdOrders]);
      setConfirmedOrders([...confirmedOrders]);
      setDoneTodayOrders([...doneTodayOrders]);
      setDoneOrders([...doneOrders]);
    }

    setLoading(false);
  }

  return (
    <MenuScreen
      loading={loading}
      createdOrders={createdOrders}
      confirmedOrders={confirmedOrders}
      doneTodayOrders={doneTodayOrders}
    />
  );
}
