import React, {useState, useEffect} from 'react';
import { HOTEL_UUID, HOTEL_DATA } from '../../utils/constants';
import HotelinfoScreen from './Hotelinfo.screen';
import { getHotel } from '../../api/hotelController';

export default function HotelinfoContainer() {
  const [loading, setLoading] = useState(false);
  const [hotel, setHotel] = useState(JSON.parse(localStorage.getItem(HOTEL_DATA)))

  useEffect(() => {
    reload()
  },[])

  const reload = () => {
    setLoading(true)
    const hotel_uuid = localStorage.getItem(HOTEL_UUID)
    getHotel(hotel_uuid)
      .then(response => {
        const hotel = response.data
        localStorage.setItem(HOTEL_DATA, JSON.stringify(hotel));
        setHotel(hotel)
      }).catch(err => {
        console.log('err: ', err);
      }).finally(() => {
        setLoading(false)
      })
  }

  return (
    <HotelinfoScreen
      loading={loading}
      hotel={hotel}
      reload={reload}
    />
  )    
}