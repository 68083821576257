export default {
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    color: "#000",
    transition: "all .2s",
  },
  paper: {
    backgroundColor: "#fff",
  },
  button: {
    textTransform: "none",
    backgroundColor: "#ECF1FE",
    color: "#4177F6",
    boxShadow: "0px 0px 0px 0px",
    fontSize: 14,
    fontWeight: "bold",
    height: 30,
    borderRadius: 6,
  },
  header: {
    justifyContent: "space-between",
    textTransform: "none",
    //backgroundColor: "#ECF1FE",
    color: "#4177F6",
    boxShadow: "0px 0px 0px 0px",
    fontSize: 14,
    fontWeight: "bold",
    height: 48,
    borderRadius: 6,
  },
};
