import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Send";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import LetterAvatar from "../../components/LetterAvatar";
import "react-chat-elements/dist/main.css";
import { ChatList, MessageList } from "react-chat-elements";

import { HOTEL_UUID, HOTEL_DATA } from "../../utils/constants";
import { getDatabase, ref, onValue, push } from "firebase/database";

import NoMessages from "./NoMessages";

import { sendPushNotification } from "../../api/notificationController";

const messageListReferance = React.createRef();

export default function ChatScreen() {
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [loading, setLoading] = useState(false);
  const hotel = JSON.parse(localStorage.getItem(HOTEL_DATA));
  const [sending, setSending] = useState(false);

  const selectedChatRef = useRef();
  const chatsRef = useRef();
  const sendingRef = useRef();

  sendingRef.current = sending;
  chatsRef.current = chats;
  selectedChatRef.current = selectedChat;

  useEffect(() => {
    const query = ref(
      getDatabase(),
      `chat/${localStorage.getItem(HOTEL_UUID)}`
    );

    return onValue(
      query,
      (snapshot) => {
        setLoading(true);

        const localChats = [];
        var fisrtUser = null;
        let selected_uuid = null;

        for (const [user_uuid, value] of Object.entries(snapshot.val())) {
          var name = "";
          var createdAt = "";
          var room = "";

          if (!fisrtUser) {
            fisrtUser = user_uuid;
            selected_uuid = !selectedChatRef.current
              ? user_uuid
              : selectedChatRef.current.user_uuid;
          }

          const localMessages = [];
          for (const [_, msg] of Object.entries(value.messages)) {
            let msgObj = JSON.parse(msg);
            createdAt = msgObj.createdAt;

            let position = "right";
            let myMessage = true;
            let userSendMsg = hotel.name;

            if (msgObj.user._id !== localStorage.getItem(HOTEL_UUID)) {
              name = msgObj.user.name;
              room = msgObj.user.room;
              position = "left";
              myMessage = false;
              userSendMsg = msgObj.user.name;
            }

            if (!!msgObj.text) {
              localMessages.push({
                myMessage,
                position: position,
                type: "text",
                text: JSON.parse(msg).text,
                date: new Date(JSON.parse(msg).createdAt),
                name: userSendMsg,
              });
            }
          }

          let unread = 0;
          if (selected_uuid !== user_uuid) {
            let chat = findChat(user_uuid);
            unread =
              chat && chat.messages.length < localMessages.length
                ? localMessages.length - chat.messages.length
                : 0;
          }

          let userChat = {
            user_uuid: user_uuid,
            title: name,
            subtitle: `quarto: ${room}`,
            className: selected_uuid === user_uuid ? "chat-selected" : "",
            date: new Date(createdAt),
            unread: unread,
            messages: localMessages,
            letterItem: {
              id: user_uuid,
              letter: `${name[0]}${name[1]}`.toUpperCase(),
            },
          };

          localChats.push(userChat);

          if (selected_uuid === user_uuid) {
            setSelectedChat(userChat);
          }
        }

        localChats.sort((a, b) => b.date - a.date);
        setChats(localChats);

        refresh();
        setSending(false);
      },
      { onlyOnce: false }
    );
  }, []);

  const refresh = () => {
    scrollToBottom();
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const findChat = (user_uuid) => {
    return chatsRef.current.find((chat) => chat.user_uuid === user_uuid);
  };

  const onSelectChat = (user_uuid) => {
    if (chatsRef.current && chatsRef.current.length > 0) {
      let userChat = findChat(user_uuid);
      setChats(
        chatsRef.current.map((item) => {
          return {
            ...item,
            className: item.user_uuid === user_uuid ? "chat-selected" : "",
            unread: item.user_uuid === user_uuid ? 0 : item.unread,
          };
        })
      );
      setSelectedChat(userChat);
      refresh();
    }
  };

  const sendMessage = (message) => {
    if (message == "") return;

    const refMessage = ref(
      getDatabase(),
      `chat/${localStorage.getItem(HOTEL_UUID)}/${
        selectedChat.user_uuid
      }/messages`
    );

    let newMessage = {
      _id: new Date(),
      createdAt: new Date(),
      text: message,
      read: true,
      user: {
        _id: localStorage.getItem(HOTEL_UUID),
        avatar: hotel.logo,
        name: hotel.name,
      },
    };
    push(refMessage, JSON.stringify(newMessage));
    setSending(true);
    sendPushNotification(hotel.name, message, selectedChat.user_uuid);
  };

  const renderHeaderMessages = () => {
    return (
      <CardHeader
        avatar={
          <LetterAvatar id={selectedChat.user_uuid} name={selectedChat.title} />
        }
        title={selectedChat.title}
      />
    );
  };

  const renderMessages = () => {
    return (
      <MessageList
        referance={messageListReferance}
        className="message-list"
        toBottomHeight={"100%"}
        dataSource={[...selectedChat.messages]}
      />
    );
  };

  function scrollToBottom() {
    let messages = document.getElementsByClassName("message-list-scroll")[0];
    if (messages) {
      setTimeout(() => {
        messages.scrollTop = messages.scrollHeight;
      }, 100);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage(message);
      setMessage("");
    }
  };

  chats.sort(function (a, b) {
    return new Date(b.unread) - new Date(a.unread);
  });

  return (
    <Container
      fixed
      sx={{
        mt: 13,
        mb: 4,
        maxHeight: "85vh",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid container xs={12}>
        <Grid
          item
          xs={3}
          sx={{
            p: 2,
            border: "1px solid #DFE0EB",
          }}
        >
          <Box
            component="main"
            sx={{
              height: "80vh",
              overflow: "auto",
            }}
          >
            <ChatList
              onClick={(userChat) => {
                onSelectChat(userChat.user_uuid);
              }}
              dataSource={chats}
            />
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={9}
          sx={{
            flex: 1,
            flexDirection: "column",
            border: "1px solid #DFE0EB",
          }}
        >
          {selectedChat ? (
            <Card elevation={0} sx={{ height: "83vh" }}>
              {renderHeaderMessages()}
              <CardContent
                className="message-list-scroll"
                disableSpacing
                sx={{
                  height: "64vh",
                  overflow: "auto",
                }}
              >
                {renderMessages()}
              </CardContent>
              <CardActions>
                <TextField
                  type="text"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  value={message}
                  placeholder="Escreva sua mensagem..."
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <Button
                  color="primary"
                  elevation={0}
                  startIcon={<CheckIcon />}
                  onClick={() => {
                    sendMessage(message);
                    setMessage("");
                  }}
                ></Button>
              </CardActions>
            </Card>
          ) : (
            <NoMessages />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
