import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  title,
  errorMessage,
  forgetPassword,
  forgetPasswordContainer,
  subtitleContainer,
  subtitle1,
  subtitle2,
} from "./Login.style";
import { USER_DATA_KEY, HOTEL_DATA, HOTEL_UUID } from "../utils/constants";
import { getUserData } from "../api/userController";
import { getHotel } from "../api/hotelController";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonLoading from "../components/layout/ButtonLoading";
import { logInWithEmailAndPassword } from "./../firebase";

import { useHotel } from "../contexts/HotelProvider";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://fivesapp.com.br/">
        Fives
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  const { setHotel, getHotelContext } = useHotel();

  let from = location.state?.from?.pathname || "/dashboard";

  const login = async () => {
    setLoading(true);
    setLoginError(false);

    logInWithEmailAndPassword(email, password)
      .then(async (response) => {
        setLoginError(false);
        localStorage.clear();
        await loadUserData();
      })
      .catch((e) => {
        console.log(e);
        setLoginError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadUserData = () => {
    getUserData()
      .then((response) => {
        const userData = response.data;
        localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
        localStorage.setItem(HOTEL_UUID, userData.hotel_uuid);
        getHotel(userData.hotel_uuid)
          .then((response) => {
            const hotel = response.data;
            localStorage.setItem(HOTEL_DATA, JSON.stringify(hotel));

            setHotel(hotel)

            navigate(from, { replace: true });
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          sm={false}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/background-fives-login.png"
              })`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item sm={12} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/fives.png"}
              height={56}
              width={149}
              alt="Fives"
              style={{ marginTop: 100 }}
            />

            <span style={title}>controller</span>

            <div style={subtitleContainer}>
              <span style={subtitle1}>Bem-vindo!</span> <br />
              <span style={subtitle2}>Conecte-se com seu email e senha</span>
            </div>

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{ fontFamily: "Poppins", width: 400 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {loginError && (
                <span style={errorMessage}>
                  Erro ao realizar login. Verifique seus dados e tente
                  novamente.
                </span>
              )}

              <ButtonLoading label="Acessar" loading={loading} />

              <div style={forgetPasswordContainer}>
                <Link
                  href="/forgot-password"
                  variant="body2"
                  style={forgetPassword}
                >
                  Esqueci minha senha?
                </Link>
              </div>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
