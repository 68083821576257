import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { getHotel } from "../api/hotelController";
import { HOTEL_DATA, HOTEL_UUID } from "../utils/constants";

export const HotelContext = createContext({});

export const HotelProvider = ({ children }) => {
  const [hotel, setHotel] = useState(null);

  async function getHotelData() {
    const hotel_uuid = localStorage.getItem(HOTEL_UUID);

    if (hotel_uuid && hotel == null) {
      const response = await getHotel(hotel_uuid);
      localStorage.setItem(HOTEL_DATA, JSON.stringify(response.data));
      setHotel(response.data);
      return response.data;
    } else {
      return hotel;
    }
  }

  const theValues = useMemo(() => ({
    hotel,
    setHotel,
    getHotelData,
  }));

  return (
    <HotelContext.Provider value={theValues}>{children}</HotelContext.Provider>
  );
};

export const useHotel = () => {
  return useContext(HotelContext);
};
