import React, { useState, useEffect } from "react";
import { getCountActivesGuestsHotel } from "./../../api/guestController";
import { getGoogleRatings, getTripAdvisorRatings } from "./../../api/rateController";
import { getCountActivesTicketsHotel } from "./../../api/ticketController";
import { USER_DATA_KEY, HOTEL_DATA, TIER_LIGHT } from "./../../utils/constants";
import { getGoogleRating, getHotel } from "../../api/hotelController";
import DashboardScreen from "./Dashboard.screen";
import { useNavigate } from "react-router-dom";

export default function DashboardContainer() {
  const [hotel, setHotel] = useState();
  const [countActiveGuests, setCountActiveGuests] = useState(0);
  const [countRatings, setCountRatings] = useState(0);
  const [googleRating, setGoogleRating] = useState(0);
  const [tripAdvisorRating, setTripAdvisorRating] = useState(0);
  const [countOpenNotifications, setCountOpenNotifications] = useState(0);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    const hotel = localStorage.getItem(HOTEL_DATA)
    if(hotel) {
      const data = JSON.parse(hotel)
      if(data.tier === TIER_LIGHT) {
        navigate('/hotel');
      }
    }
    notifica()
  }, []);

  async function notifica() {
    if (Notification.permission !== "granted") {
      await Notification.requestPermission();
    }
  }

  useEffect(() => {
    const userDataStorage = JSON.parse(localStorage.getItem(USER_DATA_KEY));

    setUserData(userDataStorage);
    getHotel(userDataStorage.hotel_uuid)
      .then((response) => {
        const hotel = response.data;
        localStorage.setItem(HOTEL_DATA, JSON.stringify(hotel));
        setHotel(hotel);
        setCountRatings(hotel.numRatings);

        getCountActivesGuestsHotel(userDataStorage.hotel_uuid)
          .then((response) => {
            const data = response.data;
            setCountActiveGuests(data.count);
          })
          .finally(() => {
            setLoading(false);
          });

        getCountActivesTicketsHotel(userDataStorage.hotel_uuid)
          .then((response) => {
            const data = response.data;
            setCountOpenNotifications(data.count);
          })
        getGoogleRatings(userDataStorage.hotel_uuid)
          .then((response) => {
            const data = response.data;
            setGoogleRating(data.rating);
          })
        getTripAdvisorRatings(userDataStorage.hotel_uuid)
          .then((response) => {
            console.log("response: ", response.data.rating);
            setTripAdvisorRating(response.data.rating);
          })
          .catch(() => {
            setTripAdvisorRating('...');
          })
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardScreen
      hotel={hotel}
      countActiveGuests={countActiveGuests}
      countRatings={countRatings}
      countOpenNotifications={countOpenNotifications}
      loading={loading}
      userData={userData}
      googleRating={googleRating}
      tripAdvisorRating={tripAdvisorRating}
    />
  );
}
