import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import DropDown from "@mui/icons-material/ArrowDropDown";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import Sidebar from "../sidebar/Sidebar";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { USER_DATA_KEY, HOTEL_DATA } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import DrawerPrecheckin from "../../components/DrawerPrecheckin";
import PopupnotificationContainer from "../../screens/popupnotification/Popupnotification.container";

import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import { useNotification } from "../../contexts/NotificationProvider";

export default function ContainerMain(props) {
  const drawerWidth = 240;
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState();
  const [hotel, setHotel] = React.useState();
  const navigate = useNavigate();
  const { sound, toggleSound } = useNotification();

  const [openDrawerPrecheckin, setOpenDrawerPrecheckin] = useState(false);
  const [openDrawerPrecheckinGroup, setOpenDrawerPrecheckinGroup] =
    useState(false);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem(USER_DATA_KEY)));
    setHotel(JSON.parse(localStorage.getItem(HOTEL_DATA)));
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const closeDrawerPrecheckin = (reload) => {
    setOpenDrawerPrecheckin(false);
    if (reload) {
      // TODO add o reload aqui da lista do precheckin, validar ainda se faz sentido chamar nesse ponto
    }
  };

  const closeDrawerPrecheckinGroup = (reload) => {
    setOpenDrawerPrecheckinGroup(false);
    if (reload) {
      // TODO add o reload aqui da lista do precheckin, validar ainda se faz sentido chamar nesse ponto
    }
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{ pr: "24px", alignItems: "center", justifyContent: "center" }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <div
            style={{
              width: "100%",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/fives-white.png"}
              height={24}
              width={65}
              alt="Fives"
              style={{ marginRight: 5 }}
            />
            <span style={{ marginTop: 1022 }}>| Controller</span>
          </div>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSound}
            size={"medium"}
            sx={{
              marginRight: "24px",
              borderRadius: 8,
            }}
          >
            {sound ? <VolumeMuteIcon /> : <VolumeOffIcon />}
          </IconButton>

          {/* nova entrada */}
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  style={{
                    width: 182,
                    height: 48,
                    marginRight: 30,
                    textTransform: "none",
                    backgroundColor: "#fff",
                    color: "#4177F6",
                    fontSize: 14,
                    fontFamily: "Poppins",
                    fontWeight: 900,
                  }}
                  variant="contained"
                  {...bindTrigger(popupState)}
                  endIcon={<DropDown />}
                >
                  Nova entrada
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    style={{
                      width: 182,
                      height: 48,
                      color: "#4177F6",
                      fontSize: 14,
                      fontFamily: "Poppins",
                      fontWeight: 900,
                    }}
                    onClick={() => setOpenDrawerPrecheckin(true)}
                  >
                    Entrada unitária
                  </MenuItem>
                  <MenuItem
                    style={{
                      width: 182,
                      height: 48,
                      color: "#4177F6",
                      fontSize: 14,
                      fontFamily: "Poppins",
                      fontWeight: 900,
                    }}
                    onClick={() => setOpenDrawerPrecheckinGroup(true)}
                  >
                    Entrada de grupo
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>

          <Box
            component="p"
            mr={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Typography
              component="span"
              variant="subtitle2"
              color="inherit"
              sx={{ flexGrow: 1, fontSize: 16, fontWeight: "bold" }}
            >
              {user ? user.name : "teste"}
            </Typography>
            <Typography
              component="span"
              variant="caption"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.5,
                marginTop: -5,
              }}
            >
              {hotel ? hotel.name : "hotel"}
            </Typography>
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={logout}
            size={"medium"}
            sx={{
              marginRight: "24px",
              borderRadius: 8,
            }}
          >
            <LogoutOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>

      {openDrawerPrecheckin ? (
        <DrawerPrecheckin {...props.hotel} close={closeDrawerPrecheckin} />
      ) : null}

      {openDrawerPrecheckinGroup ? (
        <DrawerPrecheckin
          {...props.hotel}
          inGroup={true}
          close={closeDrawerPrecheckinGroup}
        />
      ) : null}

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <Sidebar />
        </List>
      </Drawer>

      {/** Esse componente eh responsavel por mostrar as notificações */}
      <PopupnotificationContainer />

      <Outlet />
    </Box>
  );
}
