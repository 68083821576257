// Hóspeder
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { USER_DATA_KEY } from '../utils/constants';
import { getGuestsHotel } from '../api/guestController';
import Status from './Status';
import TableStyle from '../styles/table.style';

const columns = [
  { id: 'name', label: 'Nome do hóspede', minWidth: 170 },
  { id: 'email', label: 'E-mail do hóspede', minWidth: 170 },
  { id: 'room', label: 'Quarto', minWidth: 50 },
  {
    id: 'checkout_date',
    label: 'Checkout',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toLocaleString('pt-BR'),
  },
  { id: 'status', label: 'Status', align: 'center', minWidth: 50 },
];

function Guest(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    const userData = localStorage.getItem(USER_DATA_KEY);
    if (userData) {
      const userDataParsed = JSON.parse(userData);
      const hotelUID = userDataParsed.hotel_uuid;
      getGuestsHotel(hotelUID)
        .then(response => {
          setRows(response.data);
        })
        .catch(err => {
          console.log('err: ', err);
        });
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div
      style={{
        background: '#FFFFFF',
        border: '1px solid #DFE0EB'
      }}
    >
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 1240 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={5}>
                  <Typography
                    component="h2"
                    variant="title"
                    color="#252733"
                    sx={{ flexGrow: 1 }}
                  >
                    Hóspedes
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      top: 57,
                      minWidth: column.minWidth,
                      backgroundColor: '#F5F6FA',
                      color: '#9FA2B4',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell style={TableStyle.row}>
                        {row.name}
                      </TableCell>
                      <TableCell style={TableStyle.row}>
                        {row.email}
                      </TableCell>
                      <TableCell style={TableStyle.row}>
                        {row.room}
                      </TableCell>
                      <TableCell align="center" style={TableStyle.row}>
                        {row.checkout_date}
                      </TableCell>
                      <TableCell align="center" style={TableStyle.row}>
                        <Status status={row.status} />
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Resultados por página'}
        />
      </Paper>
    </div>
  )
}

export default Guest;