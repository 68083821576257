import React from "react";
import Dashboard from "../screens/dash/Dashboard.container";
import MenuDashboard from "../screens/menuDashboard/MenuDashboard.container";
import Request from "../screens/requests/TabRequests";
import Chat from "../screens/chat/Chat.container";
import Hotelinfo from "../screens/hotelinfo/Hotelinfo.container";
import Login from "../screens/Login";
import NotFound from "../screens/NotFound";
import NotPermission from "../screens/NotPermission";
import ContainerMain from "../components/layout/ContainerMain";
import { USER_DATA_KEY } from "../utils/constants";
import Scheduler from "../screens/scheduler/Scheduler.container";
import Activities from "../screens/activities/Activities.container";
import Services from "../screens/services/Services.container";
import Partners from "../screens/partners/Partners.container";
import Guests from "../screens/guests/Guests.container";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import permissions from "../enums/permissions";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route element={<ContainerMain />}>
        <Route
          path="/dashboard"
          element={
            <RequireAuth public>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/menu"
          element={
            <RequireAuth needPermission={permissions.MENU_PERMISSION}>
              <MenuDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/requests"
          element={
            <RequireAuth needPermission={permissions.REQUEST_PERMISSION}>
              <Request />
            </RequireAuth>
          }
        />
        <Route
          path="/hotel"
          element={
            <RequireAuth needPermission={permissions.INFORMATIONS_PERMISSION}>
              <Hotelinfo />
            </RequireAuth>
          }
        />
        <Route
          path="/chat"
          element={
            <RequireAuth needPermission={permissions.CHAT_PERMISSION}>
              <Chat />
            </RequireAuth>
          }
        />
        <Route
          path="/scheduler"
          element={
            <RequireAuth needPermission={permissions.SCHEDULER}>
              <Scheduler />
            </RequireAuth>
          }
        />
        <Route
          path="/activities"
          element={
            <RequireAuth needPermission={permissions.ACTIVITY_CONTROLLER}>
              <Activities />
            </RequireAuth>
          }
        />
        <Route
          path="/services"
          element={
            <RequireAuth needPermission={permissions.SERVICES_CONTROLLER}>
              <Services />
            </RequireAuth>
          }
        />
        <Route
          path="/partners"
          element={
            <RequireAuth needPermission={permissions.SERVICES_CONTROLLER}>
              <Partners />
            </RequireAuth>
          }
        />
        <Route
          path="/guests"
          element={
            <RequireAuth needPermission={permissions.GUESTS_CONTROLLER}>
              <Guests />
            </RequireAuth>
          }
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default MyRoutes;

function RequireAuth(props) {
  const [user, loading, error] = useAuthState(auth);
  let location = useLocation();

  if (loading) {
    return null;
  }
  if (!user || error) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  var permissions = [];
  const userData = localStorage.getItem(USER_DATA_KEY);

  if (userData) {
    const userDataParsed = JSON.parse(userData);

    userDataParsed.permissions.forEach((element) => {
      permissions.push(element);
    });
  }

  if (
    !permissions.includes(props.needPermission) &&
    props.public == undefined
  ) {
    return <NotPermission state={{ from: location }} replace />;
  }

  return props.children;
}
