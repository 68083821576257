import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { truncate } from "../../utils/str";

import ButtonLoading from "../../components/layout/ButtonLoading";
import Title from '../../components/Title'
import SubTitle from '../../components/SubTitle'
import TextStyle from '../../styles/text.style';
import ButtonStyle from '../../styles/button.style';
import TableStyle from '../../styles/table.style';

import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import { toast } from 'react-toastify';

import { HOTEL_UUID, WHATSAPP } from '../../utils/constants';
import DrawerInfo from './DrawerInfo';
import DrawerPhotos from './DrawerPhotos';
import DrawerPhones from './DrawerPhones';

import { saveHotelAbout, saveHotelAddress, saveHotelRules, saveHotelMessages } from '../../api/hotelController';
import Loading from '../../components/Loading';

function HotelinfoScreen(props) {

  const [openDrawerInfo, setOpenDrawerInfo] = useState(false);
  const [openDrawerPhotos, setOpenDrawerPhotos] = useState(false);
  const [openDrawerPhones, setOpenDrawerPhones] = useState(false);

  const closeDrawerInfo = (reload) => {
    setOpenDrawerInfo(false)
    if (reload) {
      props.reload()
    }
  }

  const closeDrawerPhotos = (reload) => {
    setOpenDrawerPhotos(false)
    if (reload) {
      props.reload()
    }
  }

  const closeDrawerPhones = (reload) => {
    setOpenDrawerPhones(false)
    if (reload) {
      props.reload()
    }
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          '#FFFFFF',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 13, mb: 4 }}>
        <Title text="Informações" icon={<InfoIcon color='primary' />} />
        {props.loading
          ?
          <Loading color={'#4177F6'} text={'Carregando...'} />
          :
          <Grid container spacing={1} mt={1}>
            <BoxHotel hotelInfo={props.hotel} open={setOpenDrawerInfo} />

            <Grid item md={8} sm={12} xs={12}>
              <BoxPhotos photos={props.hotel.photos} open={setOpenDrawerPhotos} />
              <BoxAbout hotelInfo={props.hotel} reload={props.reload} />
              <BoxRules hotelInfo={props.hotel} reload={props.reload} />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <BoxPhones phones={props.hotel.phones} open={setOpenDrawerPhones} />
              <BoxAddress hotelInfo={props.hotel} reload={props.reload} />
              <BoxMessages hotelInfo={props.hotel} reload={props.reload} />
            </Grid>

          </Grid>
        }
      </Container>

      {openDrawerInfo ? <DrawerInfo
        {...props.hotel}
        close={closeDrawerInfo}
      /> : null}

      {openDrawerPhotos ? <DrawerPhotos
        photos={props.hotel.photos}
        close={closeDrawerPhotos}
      /> : null}

      {openDrawerPhones ? <DrawerPhones
        {...props.hotel}
        phones={props.hotel.phones}
        close={closeDrawerPhones}
      /> : null}
    </Box>
  )
}

const BoxHotel = ({ hotelInfo, open }) => {
  if (!hotelInfo) return
  return (
    <Grid container
      sx={{
        backgroundColor: (theme) =>
          '#E8EAEE',
        border: "1px grey",
        borderRadius: "8px",
        padding: "15px"
      }}
    >
      <Grid container item md={3} sm={12} xs={12} justifyContent="center" alignSelf="center">
        <Avatar
          src={`${hotelInfo.logo}`}
          srcSet={`${hotelInfo.logo}`}
          alt='logo'
          loading="lazy"
          sx={{ minWidth: '92%', minHeight: 90, borderRadius: 0 }}
        />
      </Grid>
      <Grid container item md={7} sm={12} xs={12} direction="row" alignContent="center">
        <Typography variant="h5" style={TextStyle.titleHotelinfo}>
          {hotelInfo.name}
        </Typography>
      </Grid>
      <Grid container item md={2} sm={12} xs={12} justifyContent="flex-end" alignSelf="center">
        <Button
          variant='contained'
          color="primary"
          elevation={1}
          sx={{ width: '100%' }}
          onClick={() => open(true)}
        >
          Editar
        </Button>
      </Grid>
    </Grid>
  )
}

const BoxPhotos = ({ photos, open }) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <SubTitle text="Imagens" />
      <Grid container
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: "8px",
          padding: "20px", 
        }}
      >
        <Grid container item md={12} direction="row" justifyContent="center">
          <ImageList cols={4} gap={15} sx={{ overflowY: 'hidden' }}>
            {photos.map((item) => (
              <ImageListItem key={item.photo} sx={{ width: 200, height: 200 }}>
                <img
                  src={`${item.photo}`}
                  srcSet={`${item.photo}`}
                  alt="fotos"
                  loading="lazy"
                />
                {item.main ?
                  <StarIcon
                    sx={{
                      backgroundColor: '#4177F6',
                      borderRadius: "8px",
                      width: 40,
                      height: 40,
                      color: '#FFFFFF',
                      position: 'absolute',
                      bottom: '0px',
                      right: '0px'
                    }} /> : null}
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
        <Grid container item md={12} justifyContent="flex-end" alignSelf="center">
          <Button
            variant='contained'
            style={ButtonStyle.default}
            elevation={1}
            onClick={() => open(true)}
          >
            Editar
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const BoxRules = ({ hotelInfo, reload }) => {
  const [rules, setRules] = useState([]);
  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState();

  const styles = {
    box: {
      paddingTop: "20px",
      paddingBottom: "20px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  }

  useEffect(() => {
    setRules(hotelInfo.rules)
  }, [])

  const saveRules = (newRules) => {
    saveHotelRules(localStorage.getItem(HOTEL_UUID), {
      rules: newRules
    })
      .then(response => {
        toast.success("Dados atualizado com sucesso")
        setLoading(false)
        cancelEditSave()
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!")
      })
  }

  const addRule = async () => {

    const rule = {
      title: title,
      desc: desc,
    };

    if (isEdit) {
      let newRules = [...rules];
      newRules[indexToEdit] = rule;
      await setRules([...newRules])
      saveRules([...newRules])
    } else {
      rules.push(rule);
      await setRules([...rules]);
      saveRules([...rules])
    }
  }

  const delRule = async (index) => {
    rules.splice(index, 1);
    await setRules([...rules]);
    saveRules(rules)
  };

  const setFieldToEdit = (index) => {
    setTitle(rules[index].title)
    setDesc(rules[index].desc)
    setisEdit(true)
    setIndexToEdit(index)
  };

  const cancelEditSave = () => {
    setTitle('')
    setDesc('')
    setisEdit(false)
    setIndexToEdit(null)
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <SubTitle text="Regimento Interno" />
      <Paper container
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: "8px",
          padding: "20px"
        }}
      >

        <form name="form-add">
          <div style={styles.box}>

            <div style={{ justifyContent: 'center', textAlign: 'right' }}>
              <TextField
                id="outlined-basic"
                label="Titulo"
                variant="outlined"
                value={title}
                multiline
                rows={1}
                placeholder="Titulo da regra"
                fullWidth
                style={styles.itemBox}
                onChange={(e) => setTitle(e.target.value)}
              />

              <TextField
                id="outlined-basic"
                sx={{ mt: 2 }}
                label="Regra"
                variant="outlined"
                value={desc}
                multiline
                style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineBreak: 'anywhere' }}
                rows={10}
                placeholder="Descritivo da regra"
                fullWidth
                onChange={(e) => setDesc(e.target.value)}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                  alignItems: 'center',
                }}
              >

                <Button
                  variant="contained"
                  style={ButtonStyle.default}
                  sx={{ mt: 2.5, mr: 3 }}
                  elevation={0}
                  onClick={() => {
                    cancelEditSave()
                  }}
                >
                  Cancelar
                </Button>

                <ButtonLoading
                  disabled={title == '' || desc == ''}
                  type="button"
                  label={isEdit ? 'Atualizar' : 'Adicionar'}
                  loading={loading}
                  onClick={() => {
                    setLoading(true)
                    addRule()
                  }}
                />
              </div>
            </div>
          </div>
        </form>

        <div style={styles.box}>
          <SubTitle text="Regras" variant="subtitle2" />
          <TableContainer sx={{ maxHeight: 900, marginTop: "10px" }}>
            {loading && <LinearProgress />}
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={TableStyle.head}>
                    Titulo
                  </TableCell>
                  <TableCell align="left" style={TableStyle.head}>
                    Descrição
                  </TableCell>
                  <TableCell align="left" style={TableStyle.head}>
                  </TableCell>
                  <TableCell align="left" style={TableStyle.head}>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rules.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={TableStyle.textRow} title={row.title}>
                        {truncate(row.title)}
                      </TableCell>
                      <TableCell style={TableStyle.textRow} title={row.desc}>
                        {row.desc}
                      </TableCell>
                      <TableCell style={TableStyle.textRow} align="center">
                        <Button
                          variant="contained"
                          style={ButtonStyle.default}
                          elevation={0}
                          onClick={() => {
                            setLoading(true)
                            delRule(index)
                          }}
                        >
                          <CloseIcon
                            color="primary"
                            sx={{
                              borderRadius: "2px",
                            }}
                          />
                        </Button>
                      </TableCell>
                      <TableCell style={TableStyle.textRow} align="center">
                        <Button
                          variant="contained"
                          style={ButtonStyle.default}
                          elevation={0}
                          onClick={() => {
                            setFieldToEdit(index)
                          }}
                        >
                          <EditIcon
                            color="primary"
                            sx={{
                              borderRadius: "2px",
                            }}
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    </div>
  )
}

const BoxAbout = ({ hotelInfo, reload }) => {
  const [about, setAbout] = useState('');

  useEffect(() => {
    if (hotelInfo) {
      setAbout(hotelInfo.description)
    }
  }, [])

  const saveAbout = () => {
    saveHotelAbout(localStorage.getItem(HOTEL_UUID), {
      description: about
    })
      .then(response => {
        toast.success("Dados atualizado com sucesso")
        reload()
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!")
      })
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <SubTitle text="Sobre" />
      <Paper container
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: "8px",
          padding: "20px"
        }}
      >
        <TextField
          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineBreak: 'anywhere' }}
          id="outlined-basic"
          label="Descritivo"
          variant="outlined"
          value={about}
          multiline
          rows={10}
          placeholder="Descritivo"
          fullWidth
          onChange={(e) => {
            setAbout(e.target.value)
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px"
          }}
        >
          <Button
            variant='contained'
            color="primary"
            elevation={0}
            startIcon={<CheckIcon />}
            onClick={saveAbout}
          >
            Salvar
          </Button>
        </div>
      </Paper>
    </div>
  )
}

const BoxPhones = ({ phones, open }) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <SubTitle text="Telefones" />
      <Grid container
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: "8px",
          padding: "20px"
        }}
      >
        <Grid container item md={12} direction="column">
          <TableContainer sx={{ maxHeight: 440, marginTop: "10px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={TableStyle.head}
                  >
                    Título
                  </TableCell>
                  <TableCell
                    align="left"
                    style={TableStyle.head}
                  >
                    Número
                  </TableCell>
                  <TableCell
                    align="left"
                    style={TableStyle.head}
                  >
                    Whatsapp
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {phones
                  .map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell style={TableStyle.textRow}>
                          {row.contact_title}
                        </TableCell>
                        <TableCell style={TableStyle.textRow}>
                          {row.number}
                        </TableCell>
                        <TableCell style={TableStyle.textRow} align="center">
                          {row.type === WHATSAPP ? <CheckIcon
                            color="primary"
                            sx={{
                              background: "#E8EAEE",
                              borderRadius: "2px"
                            }} /> : null}
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={1} alignItems="center">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "5px",
                width: "100%"
              }}
            >
              <Button
                variant='contained'
                style={ButtonStyle.default}
                elevation={0}
                onClick={() => open(true)}
              >
                Editar
              </Button>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}

const BoxAddress = ({ hotelInfo, reload }) => {
  const [address, setAddress] = useState(hotelInfo.address)

  const saveAddress = () => {
    saveHotelAddress(localStorage.getItem(HOTEL_UUID), {
      address: address
    })
      .then(response => {
        toast.success("Dados atualizado com sucesso")
        reload()
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!")
      })
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <SubTitle text="Endereço" />
      <Grid container
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: "8px",
          padding: "20px"
        }}
      >
        <Grid container item md={12} direction="column">
          <TextField
            id="outlined-basic"
            label="Endereço"
            variant="outlined"
            value={address}
            multiline
            rows={3}
            placeholder="Endereço"
            fullWidth
            sx={{ marginTop: "10px" }}
            onChange={(e) => setAddress(e.target.value)}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px"
            }}
          >
            <Button
              variant='contained'
              color="primary"
              elevation={0}
              startIcon={<CheckIcon />}
              onClick={saveAddress}
            >
              Salvar
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const BoxMessages = ({ hotelInfo, reload }) => {
  const [messages, setMessages] = useState(["","",""])

  useEffect(() => {
    if (hotelInfo && hotelInfo.messages) {
      setMessages(hotelInfo.messages)
    }
  }, [])

  const saveMessages = () => {
    saveHotelMessages(localStorage.getItem(HOTEL_UUID), {
      messages: messages
    })
      .then(response => {
        toast.success("Dados atualizado com sucesso")
        reload()
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!")
      })
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <SubTitle text="Mensagens em destaque" />
      <Grid container
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: "8px",
          padding: "20px"
        }}
      >
        <Grid container item md={12} direction="column">
          {messages.map((item, index) => (
            <TextField
              key={index}
              label={`Mensagem ${index + 1}`}
              variant="outlined"
              value={item}
              multiline
              rows={3}
              fullWidth
              sx={{ marginTop: "10px" }}
              onChange={(e) => {
                messages[index] = e.target.value.length <= 120 ? e.target.value : e.target.value.substring(0, 120)
                setMessages([...messages])
              }}
              helperText={`${item.length}/120`}
            />
          ))}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px"
            }}
          >
            <Button
              variant='contained'
              color="primary"
              elevation={0}
              startIcon={<CheckIcon />}
              onClick={saveMessages}
            >
              Salvar
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default HotelinfoScreen