import React, { useState, useRef, useEffect } from "react";
import {
  HOTEL_UUID,
  TYPE_CHAT,
  TYPE_ORDER,
  TYPE_REQUEST,
} from "../../utils/constants";

import { getDatabase, ref, onValue, set } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { db } from "../../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useNotification } from "../../contexts/NotificationProvider";

export default function PopupnotificationContainer() {
  const navigate = useNavigate();

  const [updateRead, setUpdateRead] = useState(false);

  const updateReadRef = useRef();
  updateReadRef.current = updateRead;

  const { addChat, addOrder, addRequest, sound } = useNotification();

  useEffect(() => {
    listenerChat();
    listenerOrder();
    listenerRequest();
  }, []);

  const listenerChat = () => {

    const query = ref(
      getDatabase(),
      `chat/${localStorage.getItem(HOTEL_UUID)}`
    );

    onValue(
      query,
      (snapshot) => {
        if (updateReadRef.current) {
          console.log("Ignorar evento pq esta marcando mensagem como lida");
          return;
        }
        if (!snapshot.val()) return
        let msgObj;
        let userSendMsg;
        let key;
        const hotel_uuid = localStorage.getItem(HOTEL_UUID);
        for (const [user_uuid, value] of Object.entries(snapshot.val())) {
          for (const [msg_uuid, msg] of Object.entries(value.messages)) {
            try {
              msgObj = JSON.parse(msg);
              if (!msgObj.read) {
                userSendMsg = msgObj.user.name;
                key = msg_uuid;
                if (user_uuid !== hotel_uuid) {
                  showPopup(TYPE_CHAT, userSendMsg, msgObj.text, key, () => {
                    navigate("/chat");
                  });
                }

                updateMsgRead(msgObj, user_uuid, msg_uuid);
              }
            } catch (e) {
              console.log("usuario: ", user_uuid, " msg: ", msg_uuid);
            }
          }
        }
        setUpdateRead(false);
      },
      { onlyOnce: false }
    );
  };

  const updateMsgRead = (msgObj, user_uuid, msg_uuid) => {
    setUpdateRead(true);

    const refMessage = ref(
      getDatabase(),
      `/chat/${localStorage.getItem(
        HOTEL_UUID
      )}/${user_uuid}/messages/${msg_uuid}`
    );
    msgObj.read = true;
    set(refMessage, JSON.stringify(msgObj));
  };

  const showPopup = (type, title, msg, key, action) => {
    enqueueSnackbar("", {
      key: key,
      variant: "popup",
      persist: true,
      data: {
        type: type,
        title: title,
        description: msg,
      },
      action: action,
      sound: sound,
    });

    if (Notification.permission === "granted") {
      const notification = new Notification(title, {
        icon: 'https://play-lh.googleusercontent.com/w1nf7Y30cUfc4iHUBBOQmWCcFQHbmROSTfDLP-CHNXp6b4rjh42ZakpUj7YXIf8ncDDE=w480-h960-rw',
        body: msg,
      });

      notification.onclick = () => {
        action();
      }
    }

    handleCounter(type);
  };

  const handleCounter = (type) => {
    switch (type) {
      case TYPE_CHAT:
        addChat();
        break;
      case TYPE_ORDER:
        addOrder();
        break;
      case TYPE_REQUEST:
        addRequest();
        break;
    }
  };

  const listenerOrder = () => {
    const starCountRef = ref(
      getDatabase(),
      `orders/${localStorage.getItem(HOTEL_UUID)}`
    );

    onValue(
      starCountRef,
      async (snapshot) => {
        const data = snapshot.val();
        if (!data) return
        for (const [key, value] of Object.entries(data)) {
          for (const [_, value] of Object.entries(data[key])) {
            if (value.status == "created") {
              showPopup(
                TYPE_ORDER,
                `Pedido quarto ${value.room}`,
                "Solicitação de reifeição",
                new Date().getTime(),
                () => {
                  navigate("/menu");
                }
              );
            }


          }
        }
      },
      { onlyOnce: false }
    );
  };

  const listenerRequest = () => {
    const q = query(
      collection(db, `requests/${localStorage.getItem(HOTEL_UUID)}/request`),
      where("status", "==", "open")
    );

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        showPopup(
          TYPE_REQUEST,
          doc.data().title,
          doc.data().problem,
          new Date().getTime(),
          () => {
            navigate("/requests");
          }
        );
      });
    });
  };
  return <></>;
}
