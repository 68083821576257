import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
const dog = require('../../assets/ops-dog.png')

export default function NoMessages() {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={dog} alt="Fives" />
        <h1>Fives Chat</h1>
        <p style={{ fontSize: 14,  textAlign: 'center' }}>
          As mensagens de chats dos hóspede irão aparecer aqui!!!
        </p>
      </Box>
    </Container>
  )
}