import axios from 'axios';

export function getHotel(hotel_uuid) {
  return axios.get(`/api/v1/hotel/${hotel_uuid}`)
}

export function saveHotel(hotel_uuid, data) {
  return axios.put(`/api/v1/hotel/${hotel_uuid}`, data)
}

export function saveHotelInfo(hotel_uuid, data) {
  return axios.put(`/api/v1/hotel/info/${hotel_uuid}`, data)
}

export function saveHotelAbout(hotel_uuid, data) {
  return axios.put(`/api/v1/hotel/about/${hotel_uuid}`, data)
}

export function saveHotelRules(hotel_uuid, data) {
  return axios.put(`/api/v1/hotel/rules/${hotel_uuid}`, data)
}

export function saveHotelAddress(hotel_uuid, data) {
  return axios.put(`/api/v1/hotel/address/${hotel_uuid}`, data)
}

export function saveHotelPhones(hotel_uuid, data) {
  return axios.put(`/api/v1/hotel/phones/${hotel_uuid}`, data)
}

export function uploadPhotosHotel(hotel_uuid, data) {
  return axios.put(`/api/v1/hotel/photos/${hotel_uuid}`, data)
}

export function saveHotelMessages(hotel_uuid, data) {
  return axios.put(`/api/v1/hotel/messages/${hotel_uuid}`, data)
}