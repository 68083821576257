import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme-fives";
import { SnackbarProvider } from "notistack";
import PopupnotificationScreen from "./screens/popupnotification/Popupnotification.screen";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          Components={{
            popup: PopupnotificationScreen,
          }}
          maxSnack={10}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          preventDuplicate={true}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
