import React, { useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { paginationRequests } from '../../api/requestsController';
import { USER_DATA_KEY } from '../../utils/constants';
import DrawerRequest from './DrawerRequest';
import StatusRequest from './StatusRequest';
import Loading from '../../components/Loading';

const columns = [
  { id: 'title', label: 'Título', minWidth: 100 },
  { id: 'problem', label: 'Problema', minWidth: 100 },
  { id: 'local', label: 'Local', minWidth: 100 },
  {
    id: 'createAt',
    label: 'Data',
    minWidth: 110,
    align: 'center'
  },
  { id: 'room', label: 'Quarto', minWidth: 50, align: 'center' },
  { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
  { id: '', label: '', minWidth: 100 },
];

const styleRow = {
  fontSize: '14px',
  lineHeight: '20px',
  color: '#252733',
  fontWeight: '600',
}

const styleTitle = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '19px',
  lineHeight: '28px',
  letterSpacing: '0.4px',
  color: '#252733'
}

const styleButton = {
  textTransform: 'none',
  backgroundColor: '#ECF1FE',
  color: '#4177F6',
  boxShadow: '0px 0px 0px 0px'
}

function TabItemRequest(props) {
  const [active, setActive] = React.useState(props.active);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [hotelUID, setHotelUID] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [itemSelected, setItemSelected] = React.useState(null);
  const [openRequest, setOpenRequest] = React.useState(false);

  useEffect(() => {
    const userData = localStorage.getItem(USER_DATA_KEY);
    if (userData) {
      const userDataParsed = JSON.parse(userData);
      const hotelUID = userDataParsed.hotel_uuid;
      setHotelUID(hotelUID);

      fetchData(hotelUID, null, rowsPerPage, true, active);
    }
  }, []);

  async function fetchData(hotelUID, lastDoc, rowsPerPage, next, active) {
    setLoading(true);
    await paginationRequests(hotelUID, lastDoc, rowsPerPage, next, active)
      .then(async response => {
        setRows(response.data);

        let active = 0
        let notActive = 0

        await response.data.forEach(element => {
          element.status != 'done' ? active++ : notActive++
        });

        if (props.conterActive) {
          props.conterActive(active)
        }

        if (props.conterNotActive) {
          props.conterNotActive(notActive)
        }
      })

    setLoading(false);
  }

  const handleChangePage = (event, newPage) => {
    let next = newPage > page
    let doc = null;
    if (rows.length > 0) {
      doc = next ? rows[rows.length - 1] : rows[0]
    }
    fetchData(hotelUID, doc, rowsPerPage, next, active);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData(hotelUID, null, event.target.value, active);
  };

  const showRequest = () => {
    setOpenRequest(true);
  }

  const closeRequest = () => {
    setOpenRequest(false);
    setItemSelected(null);
  }

  return (
    <Grid container spacing={3} style={{ marginTop: 20 }}>
      <Grid item xs={12}>
        {loading
          ?
          <Loading color={'#4177F6'} text={'Carregando...'} />
          :
          <>
            <p style={styleTitle}>
              {active ? 'Chamados ativos' : 'Histórico de chamados'}
            </p>
            <Paper sx={{ width: '100%' }}>
              <TableContainer>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            top: 57,
                            minWidth: column.minWidth,
                            backgroundColor: '#F5F6FA',
                            color: '#9FA2B4',
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length > 0 && rows
                      .map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell style={styleRow}>
                              {row.title}
                            </TableCell>
                            <TableCell style={styleRow}>
                              {row.problem}
                            </TableCell>
                            <TableCell style={styleRow}>
                              {row.local}
                            </TableCell>
                            <TableCell align="center" style={styleRow}>
                              {row.createAt}
                            </TableCell>
                            <TableCell align="center">
                              {row.user_room}
                            </TableCell>
                            <TableCell align="center">
                              <StatusRequest status={row.status} />
                            </TableCell>
                            <TableCell align="right" style={styleRow}>
                              <Button
                                variant='contained'
                                style={styleButton}
                                elevation={0}
                                onClick={() => {
                                  setItemSelected(row)
                                  showRequest()
                                }}
                              >
                                Detalhes
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={rows.length !== rowsPerPage ? rows.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={'Resultados por página'}
              />
            </Paper>
          </>
        }
      </Grid>
      {openRequest ? <DrawerRequest {...itemSelected} close={closeRequest} reload={props.reload} /> : null}
    </Grid>
  )
}

export default TabItemRequest;