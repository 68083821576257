import * as React from 'react';
import Avatar from '@mui/material/Avatar';

const stringToColor = (str) => {
  var hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  var colour = '#'
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff
    value = (value % 150) + 50
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

function stringAvatar(id, name) {
  return {
    sx: {
      bgcolor: stringToColor(id),
    },
    children: `${name[0]}${name[1]}`.toUpperCase(),
  };
}

function LetterAvatar(props) {
  return (
      <Avatar {...stringAvatar(props.id, props.name)} />
  );
}

export default LetterAvatar;