import React, { useEffect } from "react";
import Chat from "./Chat.screen";
import { useNotification } from "../../contexts/NotificationProvider";

export default function ChatContainer() {
  const { resetChat } = useNotification();
  useEffect(() => {
    resetChat();
  }, []);
  return <Chat />;
}
