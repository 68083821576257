import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import DrawerNotifications from './DrawerNotifications';
import { getTopFiveTickects } from '../../api/ticketController';
import { USER_DATA_KEY } from '../../utils/constants';
import BoxItemNotifications from './BoxItemNotifications';

const styles = {
  title: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '19px',
    color: '#252733',
    letterSpacing: '0.4px',
    lineHeight: '24px'
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.0015em',
    color: '#252733'
  },
  description: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#707070'
  },
  date: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4'
  },
  boxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    minHeight: "100px",
    width: "100%",
    border: '1px solid #DFE0EB',
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    border: '1px solid #DFE0EB',
  },
  btnAction: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'right',
    letterSpacing: '0.2px',
    textTraansform: 'none',
    color: '#3751FF',
    cursor: 'pointer'
  }
}
function BoxNotificationHelper(props) {
  const [openNotification, setOpenNotification] = useState(false)
  const [notifications, setNotifications] = useState(null)

  useEffect(() => {
    const userData = localStorage.getItem(USER_DATA_KEY);
    if (userData) {
      const userDataParsed = JSON.parse(userData);
      const hotelUID = userDataParsed.hotel_uuid;
      loadingNotifications(hotelUID)
    }
  }, [])

  const loadingNotifications = (hotelUID) => {
    getTopFiveTickects(hotelUID)
      .then(response => {
        setNotifications(response.data);
      })
  }

  const showNotification = () => {
    setOpenNotification(true);
  }

  const closeNotification = () => {
    setOpenNotification(false);
  }

  const renderNotification = () => {
    if (notifications && notifications.length > 0) {
      return notifications.map((item, index) => (<BoxItemNotifications key={index} {...item} />))
    }
  }

  return (
    <Paper
      component="div"
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        border: "1px grey",
        borderRadius: "4px",
        backgroundColor: "#F5F5F5"
      }}
    >
      <Box
        component="div"
        sx={styles.boxRow}
      >
        <span style={styles.title}>
          Últimas notificações
        </span>
        {/* <span
          style={styles.btnAction}
          onClick={showNotification}
        >Criar notificacão</span> */}
      </Box>
      {renderNotification()}

      {openNotification ? <DrawerNotifications close={closeNotification} /> : null}
    </Paper>
  );
}

export default BoxNotificationHelper;