import React, { useRef, useEffect } from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import style from "./Popupnotification.style";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import RequestIcon from "@mui/icons-material/ConfirmationNumber";
import InfoIcon from "@mui/icons-material/Info";
import ChatIcon from "@mui/icons-material/Chat";
import { TYPE_CHAT, TYPE_ORDER, TYPE_REQUEST } from "../../utils/constants";

const chat_sound = require("../../assets/sounds/notification-sound.mp3");

const PopupnotificationScreen = React.forwardRef((props, ref) => {
  const { id, data, sound } = props;
  const { closeSnackbar } = useSnackbar();
  const audioRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (sound) {
        audioRef.current.play();
      }
    }, 1000);
  }, []);

  const handleDismiss = React.useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleAction = React.useCallback(() => {
    closeSnackbar(id);
    props.action();
  }, [id, closeSnackbar]);

  const renderIcon = () => {
    if (data.type === TYPE_CHAT) {
      return <ChatIcon fontSize="small" />;
    }
    if (data.type === TYPE_ORDER) {
      return <FastfoodIcon fontSize="small" />;
    }
    if (data.type === TYPE_REQUEST) {
      return <RequestIcon fontSize="small" />;
    }
    return <InfoIcon fontSize="small" />;
  };

  return (
    <SnackbarContent ref={ref}>
      <Card sx={{ maxWidth: 450, minWidth: 450, height: 50 }}>
        <CardActions style={style.header}>
          <IconButton size="small">{renderIcon()}</IconButton>
          <Box sx={{ flex: 1, paddingLeft: 1 }}>
            <Typography variant="body2">{data.title}</Typography>
          </Box>
          <Button
            size="meddium"
            color="primary"
            style={style.button}
            onClick={handleAction}
          >
            Visualizar
          </Button>
          <IconButton size="small" onClick={handleDismiss}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </CardActions>
      </Card>
      <div>
        <audio id="soundNotification" src={chat_sound} ref={audioRef}></audio>
      </div>
    </SnackbarContent>
  );
});

export default PopupnotificationScreen;
